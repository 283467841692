import { Message } from 'element-ui'
import { setGuid } from './util'
// 比较函数
export function compare(property) {
  return function(a, b) {
    const value1 = a[property]
    const value2 = b[property]
    return value1 - value2
  }
}

function removeEmptyRows(json) {
  return json.filter(v => v['题目'] !== undefined)
}

function validateQuestionType(v, index) {
  const validTypes = ['单选', '多选', '填空', '排序', '问答']
  if (!v['题目'] || !v['类型'] || !validTypes.includes(v['类型'])) {
    Message.error(`第${index + 1}题：题目类型只能为单选、多选、填空、排序、问答`)
    return false
  }
  return true
}

// 填空题
function processBlankFill(v) {
  let title = (v['题目'] || '').toString().replace(/(\s*)/g, '')
  title = title.replace(/<填空>/g, '<filter></filter>')
  let answers = []
  if (v['推荐/正确选项（选填）']) {
    answers = v['推荐/正确选项（选填）'].split('；').map((d, i) => ({ title: d, isCorrect: true, orderIndex: i }))
  }
  answers.sort(compare('orderIndex'))
  return {
    title: title,
    answerType: 'blank_fill',
    answers: answers,
    examExpand: '',
    analysis: (v['题目解析（选填）'] || '').toString()
  }
}

// 多选题
function processMultipleChoice(v) {
  const answers = []
  let hasCorrectOption = false
  for (const t in v) {
    if (t.startsWith('选项') && v[t]) {
      const isCorrect = v['推荐/正确选项（选填）']?.includes(t)
      answers.push({ title: v[t], isCorrect: isCorrect, orderIndex: parseInt(t.replace('选项', ''), 10) - 1 })
      hasCorrectOption = hasCorrectOption || isCorrect
    }
  }

  if (answers.length < 2) {
    throw new Error('多选题至少需要两个选项，并且至少有一个推荐/正确选项')
  }

  answers.sort(compare('orderIndex'))
  return {
    title: (v['题目'] || '').toString(),
    answerType: 'multiple',
    answers: answers,
    examExpand: '',
    analysis: (v['题目解析（选填）'] || '').toString()
  }
}

// 单选题
function processSingleChoice(v) {
  const answers = []
  let hasCorrectOption = false
  for (const t in v) {
    if (t.startsWith('选项') && v[t]) {
      const isCorrect = v['推荐/正确选项（选填）']?.includes(t)
      answers.push({ title: v[t], isCorrect: isCorrect, orderIndex: parseInt(t.replace('选项', ''), 10) - 1 })
      hasCorrectOption = hasCorrectOption || isCorrect
    }
  }

  if (answers.length < 2) {
    throw new Error('单选题至少需要两个选项')
  }

  answers.sort(compare('orderIndex'))
  return {
    title: (v['题目'] || '').toString(),
    answerType: 'single',
    answers: answers,
    examExpand: '',
    analysis: (v['题目解析（选填）'] || '').toString()
  }
}

// 问答题
function processShortAnswer(v) {

  let examExpand = ''
  for (const t in v) {
    if (t.startsWith('推荐/正确选项（选填）')) {
      examExpand = v[t]
    }
  }

  return {
    title: (v['题目'] || '').toString(),
    answerType: 'text_fill',
    answers: [],
    examExpand: examExpand,
    analysis: (v['题目解析（选填）'] || '').toString()
  }
}

// 排序题
function processSortQuestion(v) {

  const answers = []
  for (const t in v) {
    if (t.startsWith('选项') && v[t]) {
      answers.push({ title: v[t], orderIndex: parseInt(t.replace('选项', ''), 10) - 1 })
    }
  }

  if (answers.length < 2) {
    throw new Error('排序题至少需要两个选项')
  }

  answers.sort(compare('orderIndex'))
  return {
    title: (v['题目'] || '').toString(),
    answerType: 'sort',
    answers: answers,
    examExpand: v['推荐/正确选项（选填）']?.replace(/选项/g, '').replace(/，/g, ','),
    analysis: (v['题目解析（选填）'] || '').toString()
  }
}

function addCommonProperties(exam, categoryId, tagId, productList) {
  return {
    ...exam,
    id: setGuid(),
    isRealCanDel: true,
    save: false,
    isExport: true,
    analysis: (exam['题目解析（选填）'] || '').toString(),
    category: categoryId ? { categoryId } : undefined,
    tagInfos: tagId ? [{tagId, tagName: productList.find(item => item.tagId === tagId)?.tagName, groupId: productList.find(item => item.tagId === tagId)?.groupId, knowledgePointIds: [], groupCode: 'PRODUCT_TAG_GROUP'}] : undefined
  }
}

function mapToExamList(json, optionsList, productList) {
  return json.map((v, i) => {
    // 验证题目类型
    if (!validateQuestionType(v, i)) return

    let categoryId = null
    let tagId = null

    // 提前查找分类和产品标签，避免在最终映射中重复遍历
    optionsList.forEach(optionItem => {
      if (optionItem.title === (v['分类（选填）'] || '').toString()) {
        categoryId = optionItem.categoryId
      }
    })

    productList.forEach(productItem => {
      if (productItem.tagName === (v['产品'] || '').toString()) {
        tagId = productItem.tagId
      }
    })

    let exam = null
    switch (v['类型']) {
      case '填空':
      case '填空题':
        exam = processBlankFill(v)
        break
      case '多选':
      case '多选题':
        exam = processMultipleChoice(v)
        break
      case '单选':
      case '单选题':
        exam = processSingleChoice(v)
        break
      case '问答':
      case '问答题':
        exam = processShortAnswer(v)
        break
      case '排序':
      case '排序题':
        exam = processSortQuestion(v)
        break
      default:
        throw new Error('未知题目类型')
    }

    return addCommonProperties(exam, categoryId, tagId, productList)
  })
}

function mergeUniqueExams(currentExamList, examListToAdd) {
  if (!currentExamList.length) {
    return examListToAdd
  }
  // 构建一个字符串集合，用于存储已存在的标题和答案组合
  const existingExams = new Set(
    currentExamList.map(exam =>
      `${exam.title}-${(exam.answers || []).map(answer => answer.title).join('-')}`
    )
  )
  // 过滤出 examListToAdd 中未存在于 currentExamList 的条目
  const newExams = examListToAdd.filter(exam => {
    const newAnswerText = `${exam.title}-${(exam.answers || [])
      .map(answer => answer.title)
      .join('-')}`
    return !existingExams.has(newAnswerText)
  })
  return [...currentExamList, ...newExams]
}

export function checkExcel(json, list, optionsList, productList) {
  try {
    // 清除空行 题目为空认为空行
    const cleanedJson = removeEmptyRows(json)
    const examList = mapToExamList(cleanedJson, optionsList, productList)
    // 题目去重
    const newExamList = mergeUniqueExams(examList, list)
    Message.success('批量导入成功')
    return newExamList
  } catch (error) {
    Message.error(error.message)
    return []
  }
}
